import React from "react";
import { Link } from "gatsby";

import { IoArrowForwardOutline } from "react-icons/io5";

export default function OurSkills ( props ) {

	let keyID_box;

	const svgHelpers = <React.Fragment>
		<svg class="clipka">
            <defs>
              <clipPath id="delta" clipPathUnits="objectBoundingBox">	 
                <path d="M0,0 L0,0.856 L0.252,0.856 A0.428,0.425,0,0,0,0.572,1 A0.428,0.425,0,0,0,1,0.575 A0.428,0.425,0,0,0,0.862,0.262 L0.862,0 L0,0" />
              </clipPath>
            </defs>
          </svg>

          <svg class="clipka">
            <defs>
              <clipPath id="delta-up" clipPathUnits="objectBoundingBox">	 
                <path d="M0,0 L0,0.522 L0.002,0.522 A0.499,0.466,0,0,0,0.002,0.534 A0.499,0.466,0,0,0,0.501,1 A0.499,0.466,0,0,0,1,0.534 A0.499,0.466,0,0,0,0.997,0.479 L0.997,0 L0,0" />
              </clipPath>
            </defs>
          </svg> 
	</React.Fragment>;

	return(
		<React.Fragment>

			<section id="our-skills" class="postup">

				{props.content.map( (content_chunk, index) => (

					<div class="contentBox" key={(keyID_box = keyID_box ?? 0, keyID_box++)}>

						<div class={"salespitch theme-"+content_chunk.theme}>
							<div class="sticky_heading">
								<div class="icon">{content_chunk.icon}</div>
								<h4>{content_chunk.title_color}</h4>
							</div>
							
							<h2>{content_chunk.title_main}</h2>
							<p>{content_chunk.text}</p>

							<p>{content_chunk.text_secondary}</p>

							<Link to={content_chunk.link_href}>{content_chunk.text_link} <IoArrowForwardOutline /></Link>
						</div>

						<div class={`ilustration theme-${content_chunk.theme} ball_no${index}`}>
							<div class="ball">
								{content_chunk.img && 	<img 	src={content_chunk.img} 
																srcSet={content_chunk.img+" 1000w,"+
																		content_chunk.img_mobile+" 1100w"}
																alt="[design element: ilustration]"
																loading="lazy" 
																decoding="async"
														/>}
							</div>
						</div>
					</div>
				))}
				
				{svgHelpers}
			</section>
		</React.Fragment>
	);
}