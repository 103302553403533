import * as React from "react";

//components
import Layout         from '../components/layout.js';
import OurSkills      from '../components/our-skills.js';
import MainAnimation  from '../components/main-animation.tsx';
import TileGrid       from '../components/tile-grid.js';
import WhatWeDo       from '../components/what-we-do.js';
import ContactUs      from '../components/contact-us.js';

//content
import content_data from '../content/content-data.js';

const IndexPage = ({ location }) => {

  const localContent = content_data[location.pathname];

  return (
    <React.Fragment>
      <Layout location={location}>
        <MainAnimation  content={localContent.mainAnimation}></MainAnimation>
        <TileGrid       content={localContent.tileGrid} location={location}></TileGrid>
        <OurSkills      content={localContent.ourSkills}></OurSkills>
        <WhatWeDo       content={localContent.whatWeDo}></WhatWeDo>
        <ContactUs      content={localContent.contactUs}>   
        </ContactUs>
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage
