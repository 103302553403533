import * as React from "react";

export default function ContactUs ( props ) {
    
    return(
        <section id="contact-us">

            <h2>{props.content.heading}</h2>
            <p>{props.content.hint}</p>

            <div className="channels">
                {props.content.methods.map( (method) => (
                    <div className="box-channel">
                        {method.href_method ? <a href={`${method.href_method}:${method.value.replace(/\s/g, '')}`} title={`${method.href_title}`}>
                            <div className={`round-icon theme-${method.theme}`}>
                                {method.icon}
                            </div>
                        </a> :
                        <div className={`round-icon theme-${method.theme}`}>
                            {method.icon}
                        </div>}

                        <div>
                            {method.value}
                            {method.hint && <p>{method.hint}</p>}
                        </div>
                    </div>
                ))}
            </div>

            {props.children}     
        </section>
    );
}