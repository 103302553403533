import * as React from "react";
import { Link } from "gatsby";

export default function WhatWeDo ( props ) {
    
    return(
        <section id="what-we-do">
            <h1>{props.content.heading}</h1>
            <p>{props.content.hint}</p>

            <div className="slider">
                <div className="cards">
                    {props.content.services.map( (service) => (
                        <div className={`card theme-${service.theme}`}>
                            <div className="card_img">
                                <div className="icon">{service.icon}</div>
                            </div>
                            <h4>{service.name}</h4>
                            <div className="card_text">
                                {service.dscr}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="btn-sticky">
                    <div className="btn btn-primary btn-round">
                        <Link to="/inquiry/">{props.content.btn}</Link>
                    </div>
                </div>
                
            </div>
        </section>
    );
}