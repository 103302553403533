import React, { useLayoutEffect, useRef } from "react";
import { Link } from "gatsby";

import PhoneLeftSharp          from "../visuals/images/phone-left-sharp.png";
import PhoneLeftSharp_mobile   from "../visuals/images/phone-left-sharp-mobile.webp";
import PhoneLeftBlured         from "../visuals/images/phone-left-blur.png";
import PhoneLeftBlured_mobile  from "../visuals/images/phone-left-blur-mobile.webp";
import PhoneRight              from "../visuals/images/phone-right.png";
import PhoneRight_mobile       from "../visuals/images/phone-right-mobile.webp";

export default function MainAnimation ( {content} ) {

  const intervalID = useRef(undefined);
  
  const cssParams = {
    progress: 0,
    animated: true,
    animatedElements: [
      {
        class: 'animated_div_left',
        translateX: 5,
        get translateY() {
          return (cssParams.progress*0.25).toFixed(2);
        },
        get rotate() {
          return (360-cssParams.progress/10).toFixed(2);
        },
        blur: true,
      },
      {
        class: 'animated_div_right',
        translateX: 0,
        translateY: 0,
        get rotate() {
          return (360+10+cssParams.progress/20).toFixed(2);
        },
        blur: false,
      }
    ]
  }

  const updateCSSparams = function() {

    let lead_animation = (document.querySelector(".lead_animation") as HTMLElement);

    if(lead_animation) {
      let getProgress = () => {
        let lead_animation = (document.querySelector(".lead_animation") as HTMLElement);
        let div_animationStage        = document.querySelector(".animation_stage");
        let div_animationStage_top    = (div_animationStage as HTMLElement).offsetTop;
        let div_animationStage_height = (div_animationStage as HTMLElement).offsetHeight;
        let animation_lead_top        = (lead_animation.offsetParent as HTMLElement).offsetTop;
        let animation_lead_bottom     = animation_lead_top+lead_animation.offsetHeight;
        let room_available            = animation_lead_bottom-animation_lead_top-div_animationStage_height

        return (div_animationStage_top/room_available*100)|0;
      }

      cssParams.progress = getProgress();
      cssParams.animated = false;
    }
  }

  const animate = function() {
    
    let lead_animation = (document.querySelector(".lead_animation") as HTMLElement);

    if(lead_animation && !cssParams.animated) {

      const animateElements = function() {

        cssParams.animated = true;

        cssParams.animatedElements.forEach(animatedElement => {
          let DOMelement: HTMLElement = document.querySelector(`.${animatedElement.class}`);

          if(DOMelement) {
            DOMelement.style.transform = `translate3d(${animatedElement.translateX}vw, ${animatedElement.translateY}vh, 0) rotate(${animatedElement.rotate}deg)`;
            
            if(animatedElement.blur) {
              let child_blured = (DOMelement.querySelector(".blured") as HTMLElement);
              let child_sharp = (DOMelement.querySelector(".sharp") as HTMLElement);
              
              child_blured.style.opacity = (cssParams.progress > 50) ? '0.8' : '0';
              child_sharp.style.opacity = (cssParams.progress > 50) ? '0.5' : '1';
            }
          }
        });
      }

      window.requestAnimationFrame(animateElements);
    }
  }

  const observerCall = function (entries) {
        
    entries.forEach(entry => {
      if(entry.target.className === 'animation_stage') {
        entry.isIntersecting ? intervalID.current = setInterval(animate,200) : intervalID.current && clearInterval(intervalID.current);
      }
    }); 
  }

  const initiateObserver_leadAnimation = function() {
    let observedElement = document.querySelector(".animation_stage");

		if(observedElement) {
			let options = {
				root: null,
				rootMargin: "0px",
				threshold: 0.5,
			};
			
			let observer = new IntersectionObserver(observerCall, options);
			observer.observe(observedElement);
		}
  }

  useLayoutEffect(() => {

    if(document.querySelector(".lead_animation")) {

      window.onscroll = () => {
        updateCSSparams();
      }
      
      initiateObserver_leadAnimation();
    }
  }, []);

	return(
		<div className="lead_animation">

      <div  className="animated_div_right_wrapper">
          <img  className="animated_div_right" 
                src={PhoneRight_mobile} 
                srcSet={PhoneRight_mobile+" 1000w,"+
                        PhoneRight+" 1100w"}
                alt="[design element: animation] right phone"
                loading="lazy" 
                decoding="async" 
                width="304"
                height="652"
          />
      </div>

      <div className="animation_stage">
        <div className="animated_div_left">
          <img className="sharp" 
                src={PhoneLeftSharp_mobile} 
                srcSet={PhoneLeftSharp_mobile+" 1000w,"+
                        PhoneLeftSharp+" 1100w"}
                alt="[design element: animation] left phone"
                loading="lazy" 
                decoding="async" 
                width="504"
                height="573"
          />
          <img className="blured" 
                src={PhoneLeftBlured_mobile} 
                srcSet={PhoneLeftBlured_mobile+" 1000w,"+
                        PhoneLeftBlured+" 1100w"}
                alt="[design element: animation] blured left phone"
                loading="lazy" 
                decoding="async" 
                width="504"
                height="573"
          />
        </div>

        <div className="animation_text">
          <h1>{content.heading}</h1>
          <p>{content.content}</p>
          <div className="btn btn-primary btn-round">
              <Link to="/technologies/">{content.btn}</Link>
          </div>
        </div>
      </div>  
    </div>
	);
}